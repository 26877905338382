import Strings from './services/localization-service'

export const MESSAGE_TYPE_SUCCESS = 'MESSAGE_TYPE_SUCCESS'
export const MESSAGE_TYPE_WARNING = 'MESSAGE_TYPE_WARNING'
export const MESSAGE_TYPE_ERROR = 'MESSAGE_TYPE_ERROR'
export const FILE_UPLOAD_MAX_SIZE = 1024 * 1024 * 10
export const IMAGE_UPLOAD_MAX_SIZE = 1024 * 1024 * 5
export const ALLOWED_PHOTO_TYPES = ['image/png', 'image/jpeg', 'image/heif']

export enum FieldType {
  TEXT = 'text',
  TEXT_AREA = 'text-area',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  COLOR = 'color',
  IMAGE = 'image',
  FILE = 'file',
  RELATIONSHIP = 'relationship'
}

export enum RelationalType {
  ONE_TO_ONE = 'one_to_one',
  ONE_TO_MANY = 'one_to_many',
  MANY_TO_MANY = 'many_to_many'
}
// export const RolesType = [
//   {
//     ROLE_DIRECTOR = 'ROLE_DIRECTOR',
//     ROLE_ADMIN_MANAGER = 'ROLE_ADMIN-MANAGER',
//     ROLE_ADMIN = 'ROLE_ADMIN',
//     ROLE_HR_MANAGER = 'ROLE_HR-MANAGER',
//     ROLE_HR = 'ROLE_HR',
//     ROLE_MANAGER = 'ROLE_MANAGER',
//     ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
//     ROLE_CONTRACTOR = 'ROLE_CONTRACTOR'
//   }
// ]

export enum Rating {
  UNSATISFACTORY = 'Unsatisfactory',
  SATISFACTORY = 'Satisfactory',
  VERY_GOOD = 'Very Good',
  OUTSTANDING = 'Outstanding'
}

export enum Role_Dropdown {
  ROLE_PROJECT_MANAGER = 'Project Manager',
  ROLE_TECH_LEAD = 'Tech. Lead',
  ROLE_QA_LEAD = 'QA Lead'
}

export const fieldTypeDropdownValues = [
  {
    value: FieldType.TEXT,
    label: Strings.text
  },
  {
    value: FieldType.TEXT_AREA,
    label: Strings.textArea
  },
  {
    value: FieldType.DATE,
    label: Strings.date
  },
  {
    value: FieldType.DROPDOWN,
    label: Strings.dropdown
  },
  {
    value: FieldType.COLOR,
    label: Strings.color
  },
  {
    value: FieldType.IMAGE,
    label: Strings.image
  },
  {
    value: FieldType.FILE,
    label: Strings.file
  },
  {
    value: FieldType.RELATIONSHIP,
    label: Strings.relationship
  }
]

export const relationalDropdownValues = [
  {
    value: RelationalType.ONE_TO_ONE,
    label: Strings.oneToOne
  },
  {
    value: RelationalType.ONE_TO_MANY,
    label: Strings.oneToMany
  },
  {
    value: RelationalType.MANY_TO_MANY,
    label: Strings.manyToMany
  }
]

export const imageTypes = [
  {
    value: '.png',
    label: Strings.png
  },
  {
    value: '.jpeg',
    label: Strings.jpeg
  },
  {
    value: '.jpg',
    label: Strings.jpg
  }
]

export enum CompanyRoleTypes {
  CONTRACTOR = 'Contractor'
}

export const fileTypes = [
  {
    value: '.pdf',
    label: Strings.pdf
  },
  {
    value: '.doc',
    label: Strings.wordDoc
  },
  {
    value: '.docx',
    label: Strings.wordDocx
  },
  {
    value: '.xls',
    label: Strings.excel
  },
  {
    value: '.xlsx',
    label: Strings.excelX
  },
  {
    value: '.html',
    label: Strings.html
  }
]

export const PROJECT_STATUS = {
  ON_SCHEDULE: 'On Schedule',
  INTERMITTENT: 'Intermittent'
}
export const projectStatusArray = [
  {
    label: 'On Schedule',
    value: Strings.onSchedule
  },
  {
    label: 'Behind Schedule',
    value: Strings.behindSchedule
  },
  {
    label: 'Stalled',
    value: Strings.stalled
  },
  {
    label: 'finished',
    value: Strings.finished
  },
  {
    label: 'Intermittent',
    value: Strings.intermittent
  }
]
export const rangeArray = [
  {
    label: Strings.today,
    value: 'today'
  },
  {
    label: Strings.thisWeek,
    value: 'thisWeek'
  },
  {
    label: Strings.thisMonth,
    value: 'thisMonth'
  },
  {
    label: Strings.thisYear,
    value: 'thisYear'
  },
  {
    label: Strings.dateRange,
    value: 'dateRange'
  }
]

export const PERMSSION = {
  CREATE_SKILL: 'CREATE_SKILL',
  GET_SKILL: 'GET_SKILL',
  EDIT_SKILL: 'EDIT_SKILL',
  DELETE_SKILL: 'DELETE_SKILL',
  LIST_SKILLS: 'LIST_SKILLS',

  CREATE_DESIGNATIONS: 'CREATE_DESIGNATIONS',
  GET_DESIGNATION: 'GET_DESIGNATIONS',
  DELETE_DESIGNATIONS: 'DELETE_DESIGNATIONS',
  EDIT_DESIGNATIONS: 'EDIT_DESIGNATIONS',
  LIST_DESIGNATIONS: 'LIST_DESIGNATIONS',

  CREATE_HOLIDAYS: 'CREATE_HOLIDAYS',
  DELETE_HOLIDAYS: 'DELETE_HOLIDAYS',
  GET_HOLIDAYS: 'GET_HOLIDAYS',
  EDIT_HOLIDAYS: 'EDIT_HOLIDAYS',

  CREATE_PROJECTS: 'CREATE_PROJECTS',
  GET_PROJECT: 'GET_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  LIST_PROJECTS: 'LIST_PROJECTS',

  LIST_PROJECT_NOTES: 'LIST_PROJECT_NOTES',
  CREATE_PROJECTS_NOTES: 'CREATE_PROJECT_NOTES',
  GET_PROJECT_NOTES: 'GET_PROJECT_NOTES',
  DELETE_PROJECT_NOTES: 'DELETE_PROJECT_NOTES',
  EDIT_PROJECT_NOTES: 'EDIT_PROJECT_NOTES',

  LIST_PROJECT_EMPLOYEE: 'LIST_PROJECT_EMPLOYEE',
  ADD_PROJECT_EMPLOYEE: 'ADD_PROJECT_EMPLOYEE',
  EDIT_PROJECT_EMPLOYEE: 'EDIT_PROJECT_EMPLOYEE',
  GET_PROJECT_EMPLOYEE: 'GET_PROJECT_EMPLOYEE',
  DELETE_PROJECT_EMPLOYEE: 'DELETE_PROJECT_EMPLOYEE',

  LIST_FIN_MILESTONE: 'LIST_FIN_MILESTONE',
  CREATE_FIN_MILESTONE: 'CREATE_FIN_MILESTONE',
  GET_FIN_MILESTONE: 'GET_FIN_MILESTONE',
  EDIT_FIN_MILESTONE: 'EDIT_FIN_MILESTONE',
  DELETE_FIN_MILESTONE: 'DELETE_FIN_MILESTONE',

  ADD_PROJECT_CONTRACTOR: 'ADD_PROJECT_CONTRACTOR',
  GET_PROJECT_CONTRACTOR: 'GET_PROJECT_CONTRACTOR',
  EDIT_PROJECT_CONTRACTOR: 'EDIT_PROJECT_CONTRACTOR',
  LIST_PROJECT_CONTRACTORS: 'LIST_PROJECT_CONTRACTORS',
  DELETE_PROJECT_CONTRACTOR: 'DELETE_PROJECT_CONTRACTOR',

  CREATE_TS_PEOPLE: 'CREATE_TS_PEOPLE',
  DELETE_TS_PEOPLE: 'DELETE_TS_PEOPLE',

  EMPLOYEES_REPORT: 'EMPLOYEES_REPORT',
  DOWNLOAD_EMPLOYEE_REPORT: 'DOWNLOAD_EMPLOYEE_REPORT',

  CREATE_CONTRACTOR: 'CREATE_CONTRACTOR',
  DELETE_CONTRACTOR: 'DELETE_CONTRACTOR',
  EDIT_CONTRACTOR: 'EDIT_CONTRACTOR',
  GET_CONTRACTOR: 'GET_CONTRACTOR',
  LIST_CONTRACTOR: 'LIST_CONTRACTOR',

  CREATE_CLIENTS: 'CREATE_CLIENTS',
  GET_CLIENTS: 'GET_CLIENTS',
  LIST_CLIENTS: 'LIST_CLIENTS',
  DELETE_CLIENTS: 'DELETE_CLIENTS',
  EDIT_CLIENTS: 'EDIT_CLIENTS',

  ADD_POLICY: 'ADD_POLICY',
  EDIT_POLICY: 'EDIT_POLICY',
  DELETE_POLICY: 'DELETE_POLICY',
  GET_POLICY: 'GET_POLICY',
  GET_POLICY_LIST: 'GET_POLICY_LIST',

  GET_EMPLOYEES: 'GET_EMPLOYEES',
  DELETE_EMPLOYEES: 'DELETE_EMPLOYEES',
  CREATE_EMPLOYEES: 'CREATE_EMPLOYEES',
  EDIT_EMPLOYEES: 'EDIT_EMPLOYEES',

  TIMESHEET_REPORT: 'TIMESHEET_REPORT',
  PROJECT_REPORT: 'PROJECT_REPORT',

  LIST_TS_PEOPLE: 'LIST_TS_PEOPLE',

  ADD_EMPLOYEE_LAST_DATE: 'ADD_EMPLOYEE_LAST_DATE',

  CREATE_LDAP_USERS: 'CREATE_LDAP_USERS',
  GET_LDAP_USERS: 'GET_LDAP_USERS',
  UPDATE_LDAP_PASS: 'UPDATE_LDAP_PASS',

  LIST_EMPLOYEE_EXIT: 'LIST_EMPLOYEE_EXIT',
  GET_LEAVES_LIST: 'GET_LEAVES_LIST'
}
export const MESSAGE_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING'
}
export const LOGIN_DETAILS = 'loginDetails'
export const UNAUTHORIZED_STATUS_CODES = [401, 403]
export const UNAUTHORIZED_CODE = 'error.forbidden'
export const ACCESS_DENIED_MSG = 'You do not have access to this page.'
export const UNKNOWN_ERROR_MSG = 'Unknown error occured.'
export const EXPIRED_TOKEN_CODE = 'token.invalidExchnageToken'
export const ACCESS_DENIED_CODE = 403
export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 100,
  pageSizeOptions: ['50', '100', '200'],
  showSizeChanger: true
}
export const ROLES = {
  ADMIN: 'ADMIN'
}
export const LOGOUT_URL = '/logout?t='
export const INVALID_URL_MSG =
  'URL is invalid. You will be redirected to login page after 10 seconds.'
